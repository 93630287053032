@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'),
  url(../../shared/assets/fonts/Poppins-Thin.ttf) format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: local('Poppins'),
  url(../../shared/assets/fonts/Poppins-ExtraLight.ttf) format('truetype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: local('Poppins'),
  url(../../shared/assets/fonts/Poppins-Light.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: local('Poppins'),
  url(../../shared/assets/fonts/Poppins-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: local('Poppins'),
  url(../../shared/assets/fonts/Poppins-Medium.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: local('Poppins'),
  url(../../shared/assets/fonts/Poppins-SemiBold.ttf) format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: local('Poppins'),
  url(../../shared/assets/fonts/Poppins-Bold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: local('Poppins'),
  url(../../shared/assets/fonts/Poppins-ExtraBold.ttf) format('truetype');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: local('Poppins'),
  url(../../shared/assets/fonts/Poppins-Black.ttf) format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Rubik Doodle Shadow';
  src: local('Rubik Doodle Shadow'),
  url(../../shared/assets/fonts/RubikDoodleShadow-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Concert One';
  src: local('Concert One'),
  url(../../shared/assets/fonts/ConcertOne-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Agbalumo';
  src: local('Agbalumo'),
  url(../../shared/assets/fonts/Agbalumo-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Fugaz One';
  src: local('Fugaz One'),
  url(../../shared/assets/fonts/FugazOne-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Poppins, serif;
  font-weight: 500;
  font-size: 14px;

  ::-webkit-scrollbar {
    @apply w-2 h-2 desktop:w-3;
  }
  ::-webkit-scrollbar-track {
    @apply bg-secondaryAccent;
  }
  ::-webkit-scrollbar-thumb {
    @apply bg-primaryLight rounded-lg;
  }
  ::-webkit-scrollbar-thumb:hover {
    @apply bg-primary;
  }
}
.wrapper {
  @apply my-0 mx-auto px-4 desktop:px-30;
}

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    @apply hidden;
  }
}
