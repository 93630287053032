.radio {
  @apply m-0;
  :global {
    .ant-radio:not(.ant-radio-disabled) {
      & .ant-radio-inner::after {
        @apply bg-accent #{!important};
      }
    }

    .ant-radio-disabled {
      .ant-radio-inner {
        @apply border-accentLight #{!important};
      }
    }

    .ant-radio-inner {
      @apply bg-white border-accent scale-125 #{!important};
      &::after {
        @apply scale-65 #{!important};
      }
    }
  }
}
