.datepicker {
  :global {
    .ant-picker-input {
      font-weight: 600;
    }
  }
}
.borderless {
  @apply border-none shadow-none #{!important};
}
