.menu {
  @apply mb-7;
  @apply border-secondaryAccent;
  :global {
    .ant-menu-item {
      @apply flex items-center #{!important};
    }

    .ant-menu-item-selected {
      @apply flex items-center text-accent #{!important};
    }

    .ant-menu-item-active::after {
      @apply border-accent #{!important};
    }

    .ant-menu-item-selected::after {
      @apply border-accent border-b-[3px] #{!important};
    }
  }
}
