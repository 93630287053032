.dropdownMenu {
  :global {
    .ant-dropdown-menu {
      @apply w-fit p-0;
    }
    .ant-dropdown-menu-item {
      @apply py-3 px-4 #{!important};
    }
  }
}
