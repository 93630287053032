.collapse {
  @apply bg-secondary;
  :global {
    .ant-collapse-content {
      @apply bg-secondaryLight #{!important};
    }
    .ant-collapse-content-box {
      @apply p-6 #{!important};
    }
    .ant-collapse-header {
      @apply cursor-pointer text-black #{!important};
    }
    .ant-collapse-content-box {
      @apply p-3.5 desktop:p-6 #{!important};
    }
  }
}
