.table {
  :global {
    .ant-pagination-options {
      @apply mr-3;
    }
    .ant-table-pagination {
      @apply mx-5 #{!important};
    }
    .ant-table-thead .ant-table-cell {
      @apply bg-secondary font-medium py-4 px-6;
    }
    .ant-table-row .ant-table-cell {
      @apply border-secondaryAccent py-4 px-6 font-normal #{!important};
    }
    .ant-table-cell-row-hover {
      @apply bg-accentLight #{!important};
    }
  }
}
