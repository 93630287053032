.table {
  :global {
    .ant-table-pagination {
      @apply mx-5 #{!important};
    }
    .ant-table-thead .ant-table-cell {
      @apply bg-secondary font-medium py-4 px-6;
    }
    .ant-table-row .ant-table-cell {
      @apply border-secondaryAccent py-4 px-6 font-normal text-primary;
    }
    .ant-table-cell-row-hover {
      @apply bg-accentLight #{!important};
    }
    .ant-table-row-selected > td {
      @apply bg-accentLight #{!important};
    }
    .ant-table-column-sorter-up.active,
    .ant-table-column-sorter-down.active {
      @apply text-accent;
    }
  }
}
