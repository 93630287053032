.collapse {
  @apply space-y-2 bg-white;
  :global {
    .ant-collapse-header {
      @apply py-4 px-5 items-center #{!important};
    }
    .ant-collapse-content {
      @apply bg-white border-t-0 #{!important};
    }
  }
}
.needToPay {
  :global {
    .ant-collapse-item {
      @apply border border-error rounded-lg #{!important};
    }
  }
}

.completed {
  @apply bg-successLight #{!important};
}

.credited {
  @apply bg-warnLight #{!important};
}

.refunded {
  @apply bg-indigo-200 #{!important};
}

.paymentAndCreditNote {
  @apply bg-gradient-to-r from-successLight to-warnLight #{!important};
}

.paymentAndRefund {
  @apply bg-gradient-to-r from-successLight to-indigo-300 #{!important};
}
