.inputNumber {
  @apply text-2xl w-full bg-white;
  :global {
    .ant-input-number-input {
      @apply text-lg;
    }

    .ant-input::placeholder {
      @apply text-lg font-semibold;
    }
  }
}
.small input {
  @apply h-11;
}
.middle input {
  @apply h-14;
}
.large input {
  @apply h-16;
}
