.collapse {
  @apply mt-6 space-y-3;
  :global {
    .ant-collapse-header {
      @apply items-center py-1 pl-0 pr-2 desktop:pr-6 #{!important};
    }
    .ant-collapse-content {
      @apply bg-white #{!important};
    }
    .ant-collapse-item-active > .ant-collapse-header {
      @apply border-b border-accent bg-accentLight;
    }
    .ant-collapse-content-box {
      @apply p-0 #{!important};
    }
  }
}
