.dragger {
  :global {
    .ant-upload-list-item-name {
      @apply text-success #{!important};
    }

    .anticon-paper-clip {
      @apply text-success #{!important};
    }

    .ant-tooltip {
      @apply hidden #{!important};
    }

    .ant-upload-list {
      @apply desktop:flex desktop:justify-center mt-3;
    }

    .ant-upload-list-item-container {
      @apply desktop:w-60 desktop:h-60 #{!important};
    }
  }
}