.checkbox {
  @apply [&>*]:text-sm [&>*]:font-normal [&>*]:text-primaryLight;
  :global {
    .ant-checkbox .ant-checkbox-inner {
      @apply w-6 h-6 border-accent;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      @apply bg-accent #{!important};
    }

    .ant-checkbox-disabled .ant-checkbox-inner {
      @apply bg-white border-accentLight #{!important} ;
    }

    .ant-checkbox-inner::after {
      @apply w-2.5 h-3;
   }

   .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    @apply bg-transparent;
   }
  }
}