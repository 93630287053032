.paragraph {
  :global {
    .ant-typography-expand {
      @apply text-accent;
      &:hover {
        @apply text-primary;
      }
    }

    .ant-typography-collapse {
      @apply text-accent;
      &:hover {
        @apply text-primary;
      }
    }
  }
}
