.menu {
  :global {
    .ant-menu-item-selected {
      @apply text-accent #{!important};
    }

    .ant-menu-item-active::after {
      @apply border-accent #{!important};
    }

    .ant-menu-item:hover::after {
      @apply border-accent #{!important};
    }

    .ant-menu-item-selected::after {
      @apply border-accent #{!important};
    }

    .ant-menu-submenu-active a:hover {
      @apply text-accent #{!important}; 
    }

    .ant-menu-submenu-active a {
      @apply text-accent #{!important}; 
    }

    .ant-menu-submenu-active::after {
      @apply border-accent #{!important};
    }

    .ant-menu-submenu:hover::after {
      @apply border-accent #{!important};
    }

    .ant-menu-submenu-selected::after {
      @apply border-accent #{!important};
    }
  }
}
