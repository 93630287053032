.drawer {
  @apply rounded-t-2xl bg-secondaryLight #{!important};
  :global {
    .ant-drawer-body {
      @apply pt-2 px-4;
    }
  }
}
.drawerContainer {
  :global {
    .ant-drawer-mask {
      @apply bg-transparent;
    }
  }
}
