.input {
  @apply bg-white #{!important};
  @apply w-full;
  :global {
    .ant-input-group .ant-input {
      @apply text-lg;
    }
    .ant-input::placeholder {
      @apply text-lg font-semibold;
    }

    .ant-input-group-addon {
      @apply bg-transparent;
    }
  }
}

.small input {
  @apply h-11;
}
.middle input {
  @apply h-14;
}
.large input {
  @apply h-16;
}
