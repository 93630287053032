.collapse {
  @apply space-y-2 bg-white;
  :global {
    .ant-collapse-header {
      @apply py-4 px-5 items-center #{!important};
    }
    .ant-collapse-content {
      @apply bg-white border-t-0 #{!important};
    }
  }
}
.needToPay {
  @apply border border-error #{!important};
  :global {
    .ant-collapse-item {
      @apply border-b border-b-error rounded-lg #{!important};
    }
  }
}

.partialPayment {
  @apply bg-gradient-to-r from-successLight via-white to-white #{!important};
}

.partialCreditNote {
  @apply bg-gradient-to-r from-warnLight via-white to-white #{!important};
}

.paymentAndCreditNote {
  @apply bg-gradient-to-r from-successLight via-successLight to-warnLight #{!important};
}

.partialPaymentAndCreditNote {
  @apply bg-gradient-to-r from-successLight via-warnLight to-white #{!important};
}

.completed {
  @apply bg-successLight #{!important};
}

.refundedOrCredited {
  @apply bg-warnLight #{!important};
}
