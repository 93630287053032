.select {
  @apply rounded-md;
  @apply bg-white #{!important};

  :global {
    .ant-select-selection-item {
      @apply font-semibold;

    }
    .ant-select-selector {
      @apply p-4 #{!important};
    }

    .ant-select-selection-placeholder {
      @apply font-semibold;
    }

    .ant-select-arrow {
      right: 15px;
    }
  }
}
.small {
  @apply h-11;
}
.middle {
  @apply h-14;
}
.large {
  @apply h-16;
}
